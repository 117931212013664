<template>
  <div class="row g-0">
    <template v-for="(item, index) in tournamentlist">
      <div class="inbox" :key="index">
        <router-link
          :to="{ name: 'Condition', params: { id: item.tournamentno } }"
        >
          <img
            :src="'/images/main_sec1_' + (index + 1) + '.jpg'"
            class="d-block w-100"
          />
          <div>
            <h4 class="text-center text-truncate">{{ item.title }}</h4>
              <div class="col-auto text-truncate date">
                <i class="material-icons">date_range</i>
                {{
                  $moment(item.startdate).format("YYYY.MM.DD") +
                    "~" +
                    $moment(item.enddate).format("YYYY.MM.DD")
                }}
              </div>            
              <div class="col-auto text-truncate">
                <i class="material-icons-outlined">explore</i>
                {{ item.address }}
              </div>
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Tournament",
  props: ["tournamentlist"]
};
</script>