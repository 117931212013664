<template>
  <div class="row g-0">
    <template v-for="(item, index) in swinglist">
      <div class="inbox" :key="index">
        <router-link
          :to="{ name: 'NetizenLessonView', params: { id: item.swingno } }"
          class="d-block"
        >
          <div class="video-box">
            <img src="/img/ico_play_circle_white_32px.png" class="mov_icon" />
            <img
              src="/img/video_img.png"
              class="d-block w-100"
              v-if="browser === 'safari'"
            />
            <video
              :src="item.path + '/' + item.swingmovie"
              class="d-block w-100"
              v-else
            ></video>
          </div>
          <div class="info-box">
            <h5 class="text-truncate">
              {{ item.title || "무제" }}
            </h5>
            <p class="text-black">{{ item.nickname }}</p>
            <!-- span class="d-inline-block">{{ item.id }}</span -->
            <p>
              <i class="material-icons-outlined">textsms</i
              >{{ item.replycnt | comma }}
            </p>
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import "@/filter/common";

export default {
  name: "Lesson",
  data() {
    return {
      browser: "",
    };
  },
  props: ["swinglist"],
  methods: {
    check_browser: function () {
      let agent = navigator.userAgent.toLowerCase();
      let isiOSSafari = navigator.userAgent.match(/like Mac OS X/i)
        ? true
        : false;
      if (isiOSSafari) {
        if (agent.indexOf("safari") !== -1) {
          this.browser = "safari";
        }
      }
    },
  },
  mounted() {
    this.check_browser();
  },
};
</script>
