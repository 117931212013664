<template>
  <div>
    <div class="main_visual">
      <swiper class="swiper" ref="mainSwiper" :options="swiperOptions">
        <swiper-slide class="slide-wrap bg1">
          <div class="wrap-1680">
            <div class="slide-content">
              <strong>Improve &amp; Refine Your Game</strong>
              <p>
                주요 구질 통계 자료와 스윙 영상 분석으로 실력을 향상하고 이벤트
                게임, 토너먼트 참가 이력 및 순위를 확인해 보세요.
              </p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slide-wrap bg2">
          <div class="wrap-1680">
            <div class="slide-content">
              <strong
                >Play The Worlds At
                <span class="text-nowrap">X-GOLF</span></strong
              >
              <p>
                모든 수준의 골퍼는 세계 최고 수준의 코스와 골프 시뮬레이터에서
                경쟁하고 친목을 다질 수 있는 기회를 갖습니다.
              </p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slide-wrap bg3">
          <div class="wrap-1680">
            <div class="slide-content">
              <strong>Make Your Events Special</strong>
              <p>
                생일 파티, 동호회 모임, 기업 행사 및 각종 연회 등 특별한 날을
                위한 완벽한 장소! 당신의 이벤트를 더욱 특별하게 만드세요!
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="wrap-1680">
        <div class="pagination-wrap">
          <div class="swiper-pagination"></div>
          <button class="play">
            <i
              class="material-icons-outlined"
              v-if="stopbtn === 'y'"
              @click="swiper_stop"
              >pause</i
            >
            <i
              class="material-icons"
              v-if="playbtn === 'y'"
              @click="swiper_play"
              >play_arrow</i
            >
          </button>
        </div>
      </div>
      <!--
      <img src="images/visual_bg.png" class="pc_bg" />
      <img src="images/mo_visual_bg.png" class="mo_bg" />
      -->
    </div>
    <div class="main_tournament">
      <div class="wrap">
        <h1 class="main-title" @click="goto_url('tournament')">
          토너먼트<a><i class="material-icons">navigate_next</i></a>
        </h1>
        <span class="sub_title"
          >최첨단 골프 시뮬레이터에서 펼쳐지는 경쟁과 화합의 장을 통해 친목을
          다져보세요.
        </span>
        <Tournament v-bind:tournamentlist="tournamenttop" />
      </div>
    </div>
    <div class="bg-wrap">
      <div class="main_lesson">
        <div class="wrap">
          <h1 class="main-title" @click="goto_url('lesson')">
            네티즌 레슨<a><i class="material-icons">navigate_next</i></a>
          </h1>
          <span class="sub_title"
            >회원 간 다양한 조언으로 실력 향상은 물론 멋진 스윙도 뽐내
            보세요.</span
          >
          <Lesson v-bind:swinglist="swingtop" />
        </div>
      </div>
      <div class="main_cc">
        <div class="wrap">
          <Course v-bind:courselist="coursetop" />
        </div>
      </div>
    </div>
    <div class="main_social">
      <div class="wrap text-center">
        <h1 class="main-title">
          소셜 미디어<!-- a><i class="material-icons">navigate_next</i></a -->
        </h1>
        <span class="sub_title"
          >전 세계 방방곡곡, X-GOLF의 다양한 소식과 정보를 확인해 보세요.
        </span>
      </div>
      <Socialmedia />
    </div>
  </div>
</template>

<script>
import Tournament from "@/components/main/Tournament";
import Lesson from "@/components/main/Lesson";
import Course from "@/components/main/Course";
import Socialmedia from "@/components/main/Socialmedia";
import ax from "@/api/main";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "Main",
  data() {
    return {
      tournamenttop: [],
      swingtop: [],
      coursetop: [],
      playbtn: "n",
      stopbtn: "y",
      swiperOptions: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        loop: true,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            if (index === 0) {
              return (
                '<span class="' + className + '">Improve & Refine Game</span>'
              );
            } else if (index === 1) {
              return '<span class="' + className + '">Play The Worlds</span>';
            } else if (index === 2) {
              return (
                '<span class="' + className + '">Make Your Event Special</span>'
              );
            }
          },
        },
      },
    };
  },
  components: {
    Tournament,
    Lesson,
    Socialmedia,
    Course,
    Swiper,
    SwiperSlide,
  },
  methods: {
    get_main() {
      ax.get_main((flag, data) => {
        if (flag) {
          this.tournamenttop = data.tournamenttop;
          this.swingtop = data.swingtop;
          this.coursetop = data.coursetop;
        } else {
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    goto_url(type) {
      if (type === "tournament") {
        this.$router.push("/tournament/tournamentschedule");
      } else if (type === "lesson") {
        this.$router.push("/event/netizenlesson");
      }
    },
    swiper_stop() {
      this.$refs.mainSwiper.$swiper.autoplay.stop();
      this.stopbtn = "n";
      this.playbtn = "y";
    },
    swiper_play() {
      this.$refs.mainSwiper.$swiper.autoplay.start();
      this.stopbtn = "y";
      this.playbtn = "n";
    },
  },
  created() {
    this.get_main();
  },
};
</script>
